// theme preview images with names and descriptions
// mostly for carousel, grid, or other UI elements showing off all the themes

// notes:
// trying to focus on 3 templates: new york, madrid, sydney. setting initial index to 1
// so new york is starting theme, with madrid next and sydney on at end (to left)

const previewThemes = [
  {
    key: 'new york',
    name: 'New York',
    description: 'Professional and elegant resume template with a timeline structure.',
    imageFilename: 'christopher-johnson-sales-manager-new-york.jpeg',
  },
  {
    key: 'madrid',
    name: 'Madrid',
    description: 'Powerful modern resume template with bold section highlights.',
    imageFilename: 'elizabeth-martinez-marketing-manager-madrid.jpeg',
  },
  {
    key: 'amsterdam',
    name: 'Amsterdam',
    description: 'Modern minimalist resume template structure with graceful clarity.',
    imageFilename: 'katherine-davis-accountant-amsterdam.jpeg',
  },
  {
    key: 'stockholm',
    name: 'Stockholm',
    description: 'Perfect balance of fresh and functional resume template design.',
    imageFilename: 'jacqueline-brown-cashier-stockholm.jpeg',
  },
  {
    key: 'santiago',
    name: 'Santiago',
    description: 'Classic full-page resume template with sizable resume sections.',
    imageFilename: 'christopher-johnson-sales-manager-santiago.jpeg',
  },
  {
    key: 'vienna',
    name: 'Vienna',
    description: 'Striking modern header, professional two column template structure.',
    imageFilename: 'morgan-williams-web-developer-vienna.jpeg',
  },
  {
    key: 'tokyo',
    name: 'Tokyo',
    description: 'Tech-inspired design, minimalist page, skill-point visuals.',
    imageFilename: 'steven-miller-truck-driver-tokyo.jpeg',
  },
  {
    key: 'london',
    name: 'London',
    description: 'Classically structured resume template, for a robust career history.',
    imageFilename: 'christopher-johnson-sales-manager-london.jpeg',
  },
  {
    key: 'dublin',
    name: 'Dublin',
    description: 'A touch of personality with a well-organized resume structure.',
    imageFilename: 'morgan-williams-web-developer-dublin.jpeg',
  },
  {
    key: 'sydney',
    name: 'Sydney',
    description: 'Modern and eye-catching resume template. Beautiful contrasting structure.',
    imageFilename: 'elizabeth-martinez-marketing-manager-sydney.jpeg',
  },
]


export default previewThemes
